export const menuRoles = {
    itensMenu: {
        cadastro: {
            roles: [
                'CADASTRO_CAMPANHA_DELETAR',
                'CADASTRO_CAMPANHA_SALVAR',
                'CADASTRO_CAMPANHA_VISUALIZAR',
                'CADASTRO_COMERCIAIS_DELETAR',
                'CADASTRO_COMERCIAIS_SALVAR',
                'CADASTRO_COMERCIAIS_VISUALIZAR',
                'CADASTRO_JURADOS_DELETAR',
                'CADASTRO_JURADOS_SALVAR',
                'CADASTRO_JURADOS_VISUALIZAR'
            ],
            campanha: {
                visualizar: ['CADASTRO_CAMPANHA_VISUALIZAR'],
                salvar: ['CADASTRO_CAMPANHA_SALVAR'],
                deletar: ['CADASTRO_CAMPANHA_DELETAR']
            },
            comerciais: {
                visualizar: ['CADASTRO_COMERCIAIS_VISUALIZAR'],
                salvar: ['CADASTRO_COMERCIAIS_SALVAR'],
                deletar: ['CADASTRO_COMERCIAIS_DELETAR']
            },
            jurado: {
                visualizar: ['CADASTRO_JURADOS_VISUALIZAR'],
                salvar: ['CADASTRO_JURADOS_SALVAR'],
                deletar: ['CADASTRO_JURADOS_DELETAR']
            }
        },
        parametros: {
            roles: [
                'PARAMETRIZACAO_CATEGORIAS_DELETAR',
                'PARAMETRIZACAO_CATEGORIAS_SALVAR',
                'PARAMETRIZACAO_CATEGORIAS_VISUALIZAR',
                'PARAMETRIZACAO_ANUNCIANTES_DELETAR',
                'PARAMETRIZACAO_ANUNCIANTES_SALVAR',
                'PARAMETRIZACAO_ANUNCIANTES_VISUALIZAR'
            ],
            anunciante: {
                visualizar: ['PARAMETRIZACAO_ANUNCIANTES_VISUALIZAR'],
                deletar: ['PARAMETRIZACAO_ANUNCIANTES_DELETAR'],
                salvar: ['PARAMETRIZACAO_ANUNCIANTES_SALVAR']
            },
            categoria: {
                visualizar: ['PARAMETRIZACAO_CATEGORIAS_VISUALIZAR'],
                deletar: ['PARAMETRIZACAO_CATEGORIAS_DELETAR'],
                salvar: ['PARAMETRIZACAO_CATEGORIAS_SALVAR']
            }
        },
        relatorios: {
            roles: [
                'RELATORIOS_RELATORIO_DELETAR',
                'RELATORIOS_RELATORIO_SALVAR',
                'RELATORIOS_RELATORIO_VISUALIZAR'
            ],
            relatorio: {
                visualizar: ['RELATORIOS_RELATORIO_VISUALIZAR'],
                deletar: ['RELATORIOS_RELATORIO_DELETAR'],
                salvar: ['RELATORIOS_RELATORIO_SALVAR']
            }
        }
    }
};
