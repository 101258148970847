export const menuRoles = {
    itensMenu: {
        conteudo: {
            roles: ['TESTE_VISUALIZAR']
        },
        parametros: {
            roles: [
                'TESTE_VISUALIZAR',
                'ANUNCIANTE_SALVAR',
                'ANUNCIANTE_VISUALIZAR',
                'CATEGORIA_SALVAR',
                'CATEGORIA_VISUALIZAR'
            ],
            anunciante: {
                visualizar: ['ANUNCIANTE_VISUALIZAR'],
                salvar: ['ANUNCIANTE_SALVAR']
            },
            categoria: {
                visualizar: ['CATEGORIA_VISUALIZAR'],
                salvar: ['CATEGORIA_SALVAR']
            }
        }
    }
};
