<nav-bar
    titulo="O Melhor Comercial do Brasil"
    [iconeNotificacoes]="false"
    [botaoMenu]="true"
    [botaoPainelAplicacoes]="true"
    [linkPainelAplicacoes]="URL_LOGIN"
    (logoutEvent)="logout($event)"
></nav-bar>
<menu-lateral [itensMenu]="menuItens">
    <copyright></copyright>
</menu-lateral>
