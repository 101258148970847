import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { menuRoles } from './core/constants/roles';
import { permissaoRotaGuard } from './core/guards/permissao-rota.guard';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'campanha'
    },
    {
        path: 'categorias',
        loadChildren: () =>
            import('./pages/categorias/categorias.module').then((m) => m.CategoriasModule),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.parametros.categoria.visualizar
        }
    },
    {
        path: 'anunciantes',
        loadChildren: () =>
            import('./pages/anunciantes/anunciantes.module').then((m) => m.AnunciantesModule),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.parametros.anunciante.visualizar
        }
    },
    {
        path: 'comerciais',
        loadChildren: () =>
            import('./pages/comerciais/comerciais.module').then((m) => m.ComerciaisModule),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.cadastro.comerciais.visualizar
        }
    },
    {
        path: 'campanha',
        loadChildren: () =>
            import('./pages/campanha/campanha.module').then((m) => m.CampanhaModule),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.cadastro.campanha.visualizar
        }
    },
    {
        path: 'jurados',
        loadChildren: () => import('./pages/jurados/jurados.module').then((m) => m.JuradosModule),
        canActivate: [permissaoRotaGuard],
        data: {
            roles: menuRoles.itensMenu.cadastro.jurado.visualizar
        }
    },
    {
        path: 'nao-autorizado',
        loadChildren: () =>
            import('./pages/sem-autorizacao/sem-autorizacao.module').then(
                (m) => m.SemAutorizacaoModule
            ),
        canActivate: [permissaoRotaGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
